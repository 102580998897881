import React, { useState, useEffect, useContext, useMemo } from 'react';
import { format } from 'date-fns';
import Log from 'utils/Log';
import Overflow from 'react-overflow-indicator';
import { useParams, useHistory, Link } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppContext from 'AppContext';
import ActionsList from 'dashboard/actions/ActionsList.js';

import { checkPermission, getRole } from 'roles/checkPermission';

import apiService from 'utils/apiService';

import BoardCard from './BoardCard';
import Members from './Members';
import Integrations from './Integrations';
import TeamSettings from './TeamSettings';
import Insights from './Insights';
import Search from './Search';
import { GithubCounter } from 'react-reactions/src';
import { getTeamLink } from 'utils/misc';
import TeamPulse from '../pulse/TeamPulse';

import TeamPulseOverview from 'pulse/TeamPulseOverview';
import { CircularProgress } from '@material-ui/core';

export default function Team({ teamId, teamData, updateTeamSettings }) {
  const { menu } = useParams();
  const history = useHistory();

  const { profile, org, userMeta } = useContext(AppContext);
  const {
    actions,
    boards: boardsList,
    members: membersData,
    invite,
    name,
    isPrivate,
    isAnonymous,
    active,
    isArchived
  } = teamData;
  const [teamActions, setTeamActions] = useState(actions);
  const [boards, setBoards] = useState(boardsList);
  const [boardsData, setBoardsData] = useState();
  const [boardsInsights, setBoardsInsights] = useState({});
  const [members, setMembers] = useState(membersData);

  const { id: orgId } = org || {};
  const { role } = getRole({ orgId, teamId, userMeta });
  const [activeIndex, setActiveIndex] = useState('dashboard');
  const [isNewUser, setIsNewUser] = useState();

  const canEditBoard = checkPermission(role, 'board:edit');
  const canCreateBoard = checkPermission(role, 'board:create');
  const canDeleteBoard = checkPermission(role, 'board:delete');
  const canEditMember = checkPermission(role, 'member:edit');
  const canEditTeam = checkPermission(role, 'team:edit');
  //const noPlan = Boolean(customerId === null || paymentMeta === '');
  //const isLoading = Boolean(!boards || (boards?.length > 0 && boardsData.length === 0));

  const tabsViewProps = {};
  const validRetros = Array.isArray(boardsData) && boardsData.filter(({ status }) => status === 200);

  if (useMediaQuery('(max-width: 880px)')) {
    tabsViewProps.variant = 'scrollable';
    tabsViewProps.scrollButtons = 'auto';
  } else {
    tabsViewProps.centered = true;
  }

  const subMenu = useMemo(() => {
    const menu = [
      { title: 'Dashboard' },
      { title: 'Retros', data: boards },
      { title: 'Pulse' },
      { title: 'Members', data: members }
    ];

    if (canEditBoard) {
      menu.push({ title: 'Integrations' });
      menu.push({ title: 'Settings' });
    }

    return menu;
  }, [boards, members, canEditBoard]);

  const onDelete = async (id) => {
    const updatedBoardData = await apiService
      .delete(`/api/team/${teamId}/board/${id}`)
      .then(({ data }) => data)
      .catch((e) => e);

    if (updatedBoardData) {
      setBoards(updatedBoardData);
    }
  };

  const onSaveActions = ({ notes, newAction }) => {
    if (Array.isArray(notes)) {
      setTeamActions(notes);
      apiService.post(`/api/team/${teamId}/actions`, { actions: notes, newAction }).catch((e) => e);
    }
  };

  const handleTabChange = (value) => {
    Log('Click', `menu ${value}`);
  };

  const removeMember = (id) => {
    setMembers(members.filter((member) => member.id !== id));
  };

  const renderBoard = (boardData, i) => {
    const { data } = boardData || {};

    return (
      <BoardCard
        key={data.id || i}
        teamId={teamId}
        boardData={data}
        onDelete={onDelete}
        canDeleteBoard={canDeleteBoard}
      />
    );
  };
  const renderBoards = () => {
    const boards = () => {
      if (!boardsData) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              minHeight: '100px',
              justifyContent: 'center',
              alignContent: 'center'
            }}
          >
            <CircularProgress size={20} />
          </div>
        );
      }

      if (Array.isArray(validRetros) && validRetros.length > 0) {
        const displayBoards = [...validRetros].reverse();
        return <div className="teamBoardsContainer">{displayBoards.map(renderBoard)}</div>;
      } else {
        return (
          <div className="clouds" style={{ opacity: 0.5, height: '100px', position: 'relative' }}>
            <div
              className="cloud cloud-small cloud-orange"
              style={{
                left: '48px',
                top: '20'
              }}
            ></div>
            <div
              className="cloud cloud-1 cloud-orange"
              style={{
                right: '18px',
                top: '16px'
              }}
            ></div>
          </div>
        );
      }
    };

    return (
      <>
        <div className="panelAction panelAction--search">
          <Button href={`/team/${teamId}/board/create`} disabled={!canCreateBoard || isArchived}>
            <div>Start a retro</div>
          </Button>
          <Search teamId={teamId} />
        </div>

        <div>{boards()}</div>
      </>
    );
  };

  const renderInsights = () => {
    const {
      cards,
      cardsCount,
      actionsCount,
      emojiCount,
      id,
      name,
      peopleCount,
      timestamp,
      votesCount
    } = boardsInsights;

    const renderCard = (card) => {
      const { id, text, emojis, votes } = card;

      return (
        <div className="cardOverview" key={`card-${id}`}>
          <p className="cardContent">{text}</p>

          <div>
            <GithubCounter
              counters={emojis}
              votesCount={votes ? <span className="cardVotesCount">{votes}</span> : null}
            />
          </div>
        </div>
      );
    };

    if (!id && isNewUser) {
      return (
        <div
          style={{
            display: 'flex',
            margin: '22px 0 0 0',
            flexDirection: 'column',
            textAlign: 'center',
            minHeight: 250,
            position: 'relative'
          }}
        >
          <p>
            See summary after your first retro{' '}
            <span role="img" aria-label="">
              🔮
            </span>
          </p>
          <div className="clouds" style={{ opacity: 0.5 }}>
            <div
              className="cloud cloud-small cloud-purple"
              style={{
                right: '7%',
                bottom: '47%'
              }}
            ></div>
            <div
              className="cloud cloud-1 cloud-purple"
              style={{
                right: '20%',
                bottom: '10%'
              }}
            ></div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="prevBoard">
          <h3>Title</h3>
          <p>
            <a href={`/board/${id}`}>{name}</a>
          </p>
        </div>
        <div className="prevBoard">
          <h3>Date</h3>
          <p>{timestamp && format(timestamp, 'PPP')}</p>
        </div>
        <div className="teamDashboard-overview">
          <span>
            <span>People</span>
            {peopleCount}
          </span>
          <span>
            <span>Notes</span>
            {cardsCount}
          </span>

          <span>
            <span>Votes</span>
            {votesCount}
          </span>
          <span>
            <span>Emoji</span>
            {emojiCount}
          </span>
          <span>
            <span>Actions</span>
            {actionsCount}
          </span>
        </div>
        <div className="topCards">
          <h3>Top feedback</h3>

          <Overflow className="topCards-content">
            <Overflow.Content>{cards && cards.map(renderCard)}</Overflow.Content>
            <Overflow.Indicator direction="up">
              <div className="contentScroll-top"></div>
            </Overflow.Indicator>
          </Overflow>
        </div>
      </>
    );
  };

  const overview = () => {
    return (
      <div className="teamDashboard">
        {<Insights teamId={teamId} canCreateBoard={canCreateBoard} isArchived={isArchived} />}

        <Grid container spacing={3}>
          <Grid container item md={4} sm={12} xs={12}>
            <Paper>
              <div className="teamDashboard-grid teamDashboard-grid-2">
                <h2>Latest Retro</h2>
                {renderInsights()}
              </div>
            </Paper>
          </Grid>

          <Grid container item md={4} sm={6} xs={12}>
            <Paper>
              <div className="teamDashboard-grid teamDashboard-grid-2">
                <h2>Actions</h2>
                {actionsView()}
              </div>
            </Paper>
          </Grid>

          <Grid container item md={4} sm={6} xs={12}>
            <TeamPulseOverview
              teamId={teamId}
              actions={actions}
              canCreateBoard={canCreateBoard}
              isArchived={isArchived}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const boardsView = () => {
    return <Paper className="teamBoardsWrapper">{renderBoards()}</Paper>;
  };

  const actionsView = () => {
    return (
      <ActionsList
        onSave={onSaveActions}
        notes={teamActions}
        user={profile.sub}
        boardId="dashboard"
        teamId={teamId}
        members={members}
      />
    );
  };

  useEffect(() => {
    if (!Array.isArray(boards)) return;
    const totalBoardsCount = boards.length;
    if (totalBoardsCount > 0) {
      const lastRetroId = boards[totalBoardsCount - 1]; //
      apiService
        .get(`/api/team/${teamId}/board-insights/${lastRetroId}`)
        .then(({ data }) => {
          const actionsCount = actions.filter(({ addedIn }) => addedIn === lastRetroId).length;
          data.actionsCount = actionsCount;

          setBoardsInsights(data);
        })
        .catch((e) => e);

      Promise.all(boards.map((id) => apiService.get(`/api/team/${teamId}/board/${id}`).catch((e) => e))).then(
        (data) => {
          setBoardsData(data);
        }
      );
    } else {
      setIsNewUser(true);
      setBoardsData([]);
    }
  }, [boards, teamId, actions]);

  useEffect(() => {
    const validMenu = subMenu.filter(({ title }) => title.toLowerCase() === menu)[0];

    if (menu) {
      if (validMenu) {
        setActiveIndex(menu);
      } else {
        history.push(getTeamLink({ teamId }));
      }
    } else {
      setActiveIndex('dashboard');
    }
  }, [menu, subMenu, teamId, history]);

  return (
    <div className="team-container">
      <div className="team-container-header">
        <Tabs onChange={(e, value) => handleTabChange(value)} value={activeIndex} {...tabsViewProps}>
          {subMenu.map((item, index) => (
            <Tab
              key={index}
              value={item.title?.toLowerCase()}
              disableRipple={true}
              style={{
                width: 140
              }}
              label={<div>{item.title}</div>}
              component={Link}
              to={getTeamLink({ teamId, menu: item.title })}
            />
          ))}
        </Tabs>
      </div>
      {subMenu.map((item, index) => {
        const keyName = item.title?.toLowerCase();
        const views = [
          overview(),
          boardsView(),
          <TeamPulse teamId={teamId} canCreateBoard={canCreateBoard} isArchived={isArchived} />,
          <Members
            teamId={teamId}
            members={members}
            canEditMember={canEditMember}
            removeMember={removeMember}
            invite={invite}
            isArchived={isArchived}
          />,
          <Integrations teamId={teamId} />,
          <TeamSettings
            teamId={teamId}
            name={name}
            isPrivate={isPrivate}
            isAnonymous={isAnonymous}
            isArchived={isArchived}
            active={active}
            updateTeamSettings={canEditTeam ? updateTeamSettings : null}
          />
        ];
        return (
          <div
            key={`team-view-${teamId}-${keyName}`}
            role="tabpanel"
            hidden={keyName !== activeIndex}
            id={`team-tabpandel-${teamId}-${keyName}`}
            aria-labelledby={`team-tabpandel-${teamId}-${keyName}`}
          >
            {views[index]}
          </div>
        );
      })}
    </div>
  );
}
