import React from 'react';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import { getTeamLink } from 'utils/misc';

import MembersAvatarGroup from './MembersAvatarGroup';
import OrgTimeline from './OrgTimeline';

export default function OrgTeam({ teamId, teamData, index, teamsData }) {
  const history = useHistory();
  const { actions = [], members = [], name, isArchived /*, isPrivate*/ } = teamData;

  return (
    <tr>
      <th>
        {isArchived && (
          <span
            style={{
              background: '#eee',
              color: '#555',
              fontSize: '10px',
              margin: '2px 0',
              padding: '3px 7px',
              borderRadius: '10px',
              fontWeight: '500'
            }}
          >
            Archived
          </span>
        )}
        <span className="text-button" title={name} onClick={() => history.push(getTeamLink({ teamId }))}>
          <Truncate width={200}>{name}</Truncate>
        </span>
      </th>
      {index === 0 && (
        <td rowSpan={teamsData.length}>
          <OrgTimeline teamsData={teamsData} />
        </td>
      )}
      <td>
        <MembersAvatarGroup members={members.filter(({ role }) => role === 'admin' || role === 'owner')} />
      </td>
      <td>
        <MembersAvatarGroup members={members.filter(({ role }) => role === 'facilitator')} />
      </td>
      <td>{members.length}</td>
      <td>{actions.filter(({ status }) => status === 0).length}</td>
    </tr>
  );
}
