import React, { useState, useContext } from 'react';
import * as Sentry from '@sentry/browser';

import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from 'components/Modal/Modal';

import AppContext from 'AppContext';
import apiService from 'utils/apiService';

import AuthorVisibility from './AuthorVisibility';
import Log from 'utils/Log';

import NotificationBar from './NotificationBar';

export default function TeamSetting({ teamId, name, isPrivate, isAnonymous, isArchived, active, updateTeamSettings }) {
  const history = useHistory();
  const { org, paymentMeta } = useContext(AppContext);
  const { customerId, teams } = org || {};

  const [displayName, setDisplayName] = useState(name);
  const [displayIsPrivate, setDisplayIsPrivate] = useState(Boolean(isPrivate === true));
  const [displayAnonymity, setDisplayAnonymity] = useState(Boolean(isAnonymous !== false));
  const [isUpdated, setIsUpdated] = useState();
  const [archiveModal, setArchiveModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [error, setError] = useState();

  const noChange =
    Boolean(displayName === name) &&
    Boolean(displayIsPrivate === Boolean(isPrivate === true)) &&
    Boolean(displayAnonymity === Boolean(isAnonymous !== false));

  const noPlan = Boolean(customerId === null || paymentMeta === '');

  const onNameChange = (e) => {
    const value = e.target.value;
    setDisplayName(value);
    Log('Team Settings', `name update`);
  };

  const onPrivacyChange = (e) => {
    //important, the radio returns string
    const value = e.target.value === 'true' ? true : false;
    setDisplayIsPrivate(value);
    Sentry.captureMessage(`Set private: ${value}`);
  };

  const onAnonymityChange = (e) => {
    //important, the radio returns string
    const value = e.target.value === 'true' ? true : false;
    setDisplayAnonymity(value);
    Log('Team Settings', `Author Visibility: ${value}`);
  };

  const onArchiveTeam = (e) => {
    const newArchiveValue = Boolean(!isArchived);

    //To do: make sure team setting update single value, handle settings update, errors

    apiService
      .post(`/api/team/${teamId}/settings`, {
        isArchived: newArchiveValue
      })
      .then(() => {
        setArchiveModal(false);
        window.location.reload();
      })
      .catch((e) => {
        setError(true);
      });

    Sentry.captureMessage(`Set archived: ${newArchiveValue}`);
  };

  const onSubmit = () => {
    setIsUpdated(false);
    if (!noChange) {
      apiService
        .post(`/api/team/${teamId}/settings`, {
          name: displayName,
          isPrivate: displayIsPrivate,
          isAnonymous: displayAnonymity
        })
        .then(() => {
          updateTeamSettings({
            id: teamId,
            name: displayName,
            isPrivate: displayIsPrivate,
            isAnonymous: displayAnonymity
          });
          setIsUpdated(true);
        })
        .catch((e) => {
          setIsUpdated(false);
          setError(true);
        });
    }
  };

  const onArchiveModal = () => {
    setArchiveModal((prev) => !prev);
  };

  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
  };

  const onDeleteTeam = () => {
    apiService
      .delete(`/api/team/${teamId}`)
      .then(() => {
        setRemoveModal(false);
        window.location.pathname = '/';
      })
      .catch((e) => {
        setError(true);
      });
  };

  const renderArchiveZone = () => {
    if (Array.isArray(teams) && teams.length === 1) return null;

    if (!updateTeamSettings) return null;

    return (
      <>
        <h3>Archive & Restore</h3>
        <Paper className="teamZone">
          {isArchived ? (
            <>
              <div>
                <h4>Restore this team</h4>
                <p>Reactivate this team and resume all team activities.</p>
              </div>
              <div>
                <Button onClick={onArchiveModal}>Restore Team</Button>
              </div>
            </>
          ) : (
            <>
              <div>
                <h4>Archive this team</h4>
                <p>Pause team activities while keeping all your data safe and removing it from billing.</p>
              </div>
              <div>
                <Button onClick={onArchiveModal}>Archive Team</Button>
              </div>
            </>
          )}
        </Paper>
      </>
    );
  };

  const renderDangerZone = () => {
    if (Array.isArray(teams) && teams.length === 1) return null;

    if (!updateTeamSettings) return null;

    return (
      <>
        <h3>Danger Zone</h3>
        <Paper className="teamZone">
          <div>
            <h4>Delete this team</h4>
            <p>All data associated with this team will be deleted.</p>
          </div>
          <div>
            <Button color="danger" onClick={onRemoveModal}>
              Delete Team
            </Button>
          </div>
        </Paper>
      </>
    );
  };

  console.log(isArchived, typeof isArchived, isArchived ? 1 : 2);

  return (
    <div>
      <Paper className="teamSettings">
        <div className="teamSettings-group teamSettings-name">
          <label style={{ marginTop: '4px' }}>Team Name</label>
          <TextField value={displayName} inputProps={{ maxLength: 100 }} onChange={onNameChange} />
        </div>
        <div className="teamSettings-group teamSettings-visibility">
          <label style={{ marginTop: '10px' }}>Retro & Pulse Survey Visibility</label>
          <RadioGroup row aria-label="layout" name="layout" value={displayIsPrivate} onChange={onPrivacyChange}>
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label={
                <div className="">
                  <h3>Public</h3>
                  <p>
                    Retros visible to anyone via link, sign in <b>not</b> required.
                  </p>
                  <p>
                    Pulse surveys visible to anyone via link, sign in <b>is</b> required.
                  </p>
                </div>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              disabled={noPlan || !active}
              label={
                <div className="">
                  <h3>Private</h3>
                  {noPlan && (
                    <span className="text-button " onClick={() => history.push('/plans')}>
                      Upgrade to unlock{' '}
                      <span role="img" aria-label="">
                        🚀
                      </span>
                    </span>
                  )}
                  <p>Limit access to invited team members only.</p>
                </div>
              }
              labelPlacement="end"
            />
          </RadioGroup>
        </div>

        <div className="teamSettings-group">
          <label style={{ marginTop: '10px' }}>
            Author Visibility{' '}
            <Tooltip
              title={
                <div style={{ fontSize: '16px' }}>
                  <p>This setting will apply to all retros.</p>
                  <p>You can also override per retro from the facilitator panel.</p>
                  <p>Retro > Settings > Author Visibility</p>
                </div>
              }
              placement="top"
            >
              <InfoOutlinedIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </label>
          <AuthorVisibility value={displayAnonymity} onChange={onAnonymityChange} />
        </div>
        <p>
          {updateTeamSettings ? (
            <Button onClick={onSubmit} disabled={noChange || Boolean(displayName === '')}>
              {isUpdated && noChange ? 'Saved!' : 'Save'}
            </Button>
          ) : (
            <Tooltip title="Only team admins can make changes." placement="top">
              <Button disabled style={{ pointerEvents: 'auto' }}>
                Save
              </Button>
            </Tooltip>
          )}
        </p>
      </Paper>

      {renderArchiveZone()}
      {renderDangerZone()}

      <Modal status={archiveModal} maxWidth="sm" onClose={onArchiveModal} nonResponsive>
        <div className="modalContainer">
          {isArchived ? (
            <h2>
              Restore Team{' '}
              <span role="img" aria-label="">
                ♻️
              </span>
            </h2>
          ) : (
            <h2>
              Archive Team{' '}
              <span role="img" aria-label="">
                🗃️
              </span>
            </h2>
          )}
          <p className="modalContainer-header">{name}</p>
          {isArchived ? (
            <>
              <p>
                When you restore a team, all previous data remains accessible. Your team can create new retros and pulse
                surveys again.
              </p>
              <p>The team will be added back to your billing, and all members will regain full access to features.</p>
            </>
          ) : (
            <>
              <p>
                When you archive a team, all existing data is preserved and remains accessible. Your team won't be able
                to create new retros or pulse surveys.{' '}
              </p>
              <p>The team won't count towards your billing, and you can restore it whenever needed.</p>
            </>
          )}

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onArchiveModal}>
              Cancel
            </span>

            {isArchived ? (
              <Button onClick={onArchiveTeam}>Restore Team</Button>
            ) : (
              <Button color="danger" onClick={onArchiveTeam}>
                Archive Team
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <Modal status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive>
        <div className="modalContainer">
          <h2>
            Delete Team{' '}
            <span role="img" aria-label="">
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{name}</p>
          <p>Are you sure you want to delete this team?</p>
          <p>All data associated with this team will be deleted, and this can't be undone.</p>

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button color="danger" onClick={onDeleteTeam}>
              Delete Team
            </Button>
          </div>
        </div>
      </Modal>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </div>
  );
}
