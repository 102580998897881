import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import Modal from 'components/Modal/Modal';
import apiService from 'utils/apiService';

import AppContext from 'AppContext';
import { getTeamLink } from 'utils/misc';

const useStyles = makeStyles({
  button: {},
  input: {
    width: '80%',
    margin: '24px 0 0 0'
  }
});

const TEAMS_PER_PLAN = 3;

const getMaxTeams = (level, quantity = 1) => {
  if (!level) return 0;

  if (level.includes('level2')) {
    return quantity * TEAMS_PER_PLAN;
  }

  if (level.includes('level3')) {
    return 10000;
  }

  return 1;
};

export default function CreateTeam({ open, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  const { org, paymentMeta } = useContext(AppContext);
  const { id: orgId } = org || {};
  const [name, setName] = useState('');
  const [error, setError] = useState();
  const quantity = paymentMeta?.quantity;
  const currentLevel = paymentMeta && paymentMeta.plan?.nickname;
  const activeTeams = Object.values(org?.teamsSettings || {}).filter(({ isArchived }) => !isArchived);
  const currentTeamSize = activeTeams.length;
  const allowedMaxTeams = getMaxTeams(currentLevel, quantity);

  const onChange = (e) => {
    const value = e.target.value;
    setName(value);
    error && setError();
  };

  const onCreate = () => {
    if (name) {
      apiService
        .post(`/api/org/${orgId}/team/create`, { name })
        .then(({ data }) => {
          const teamId = data;

          const location = getTeamLink({ teamId });

          closeModal();
          window.location.pathname = location;
        })
        .catch((e) => setError('Sorry something went wrong'));
    } else {
      setError('Please enter a name');
    }
  };

  const closeModal = () => {
    onClose();
    error && setError();
    setName('');
  };

  const renderCreateTeam = () => {
    return (
      <div>
        <TextField
          className={classes.input}
          value={name}
          inputProps={{ maxLength: 60, autoFocus: true }}
          onChange={onChange}
          error={Boolean(error)}
          helperText={error}
        />
        <div className="modalContainer-actions">
          <span className="text-button" onClick={closeModal}>
            Cancel
          </span>

          <Button onClick={onCreate}>Create Team</Button>
        </div>
      </div>
    );
  };

  const renderPlan = () => {
    const hasPlan = Boolean(allowedMaxTeams > 0);
    const maxTeams = allowedMaxTeams > 1 ? 'teams' : 'team';
    return (
      <div>
        {hasPlan ? (
          <div>
            <p>{`Your current plan allows up to ${allowedMaxTeams} ${maxTeams}.`}</p>
            <p>Please upgrade your plan to allow the creation of more teams.</p>
          </div>
        ) : (
          <div>
            <p>You are currently on the Free plan which allows 1 team.</p>
            <p>Upgrade to Business for multiple teams and unlimited retros.</p>
          </div>
        )}

        <Button onClick={() => history.push('/plans')}>{hasPlan ? 'Update Plan' : 'Compare Plans'}</Button>
      </div>
    );
  };

  return (
    <Modal status={open} maxWidth="sm" onClose={closeModal} nonResponsive>
      <div className="welcomeModal">
        <h2>
          Create new team{' '}
          <span role="img" aria-label="" style={{ fontSize: '30px' }}>
            🚀
          </span>
        </h2>

        {currentTeamSize < allowedMaxTeams ? renderCreateTeam() : renderPlan()}
      </div>
    </Modal>
  );
}
