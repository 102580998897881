import React, { useState } from 'react';
import Log from 'utils/Log';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Paper from '@material-ui/core/Paper';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import apiService from 'utils/apiService';
import Member from './Member';
import { sortRole } from 'utils/misc';
import NotificationBar from 'modules/NotificationBar';

export default function Members({ teamId, members, canEditMember, removeMember, invite, isArchived }) {
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteToken, setInviteToken] = useState(invite);
  const { id, disabled } = inviteToken || {};
  const [enabled, setEnabled] = useState(!disabled);
  const [error, setError] = useState();

  const inviteLink = `https://${window.location.hostname}/invite/team/${teamId}/${
    id || 'a-new-link-will-be-generated-when-enabled'
  }`;

  const onInviteModal = () => {
    setInviteModal((prev) => !prev);
  };
  const onCopy = () => {
    Log('Invite', 'team');
  };

  const handleChange = (event) => {
    const value = event.target.checked;

    apiService
      .post(`/api/team/${teamId}/invite`, {
        disabled: !value
      })
      .then(({ data }) => {
        setInviteToken(data);
        setEnabled(value);
      })
      .catch((e) => {
        setEnabled(!value);
        setError(true);
        return e;
      });

    Log('Invite', 'toggle');
  };

  const membersView = () => {
    return members
      .sort(sortRole)
      .map((member, index) => (
        <Member
          key={`team-${teamId}-member-${index}`}
          teamId={teamId}
          member={member}
          canEditMember={canEditMember}
          removeMember={removeMember}
        />
      ));
  };

  return (
    <Paper className="teamMembers">
      <TableContainer>
        <div className="panelAction">
          {canEditMember && (
            <Button onClick={onInviteModal} disabled={isArchived}>
              + Invite
            </Button>
          )}
        </div>

        <Table className="teamMembers-table" aria-label="Memembers Table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{membersView()}</TableBody>
        </Table>
      </TableContainer>
      <Modal status={inviteModal} maxWidth="sm" onClose={onInviteModal} nonResponsive>
        <div id="team-users-modal" className="modalContainer">
          <h2>
            Invite members{' '}
            <span role="img" aria-label="">
              🕊
            </span>
          </h2>
          <p>
            <FormControlLabel
              control={<Switch checked={enabled} onChange={handleChange} name="link-enabled" color="primary" />}
              label="Enable members to join by invite link"
            />
          </p>
          <input value={inviteLink} readOnly style={{ maxWidth: 'none', filter: enabled ? 'blur(0)' : 'blur(2px)' }} />
          <p style={{ opacity: enabled ? 1 : 0.65 }}>Copy this link and share it with your team.</p>
          <div className="modalContainer-actions">
            <CopyToClipboard text={inviteLink} onCopy={onCopy}>
              <Button disabled={!enabled}>Copy link</Button>
            </CopyToClipboard>
          </div>
        </div>
      </Modal>
      {error && (
        <NotificationBar
          message="Sorry something went wrong."
          type="error"
          closeCallback={() => setError()}
          rootId="team-users-modal"
        />
      )}
    </Paper>
  );
}
