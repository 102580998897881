import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as Sentry from '@sentry/browser';

import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from 'components/CustomButtons/Button';

import NavPills from 'components/NavPills/NavPills.js';
import { APP_ENDPOINT } from 'config';
import AppContext from 'AppContext';

import { getRole, checkPermission } from 'roles/checkPermission';

import useSsoConnection from 'hooks/useSsoConnection';

import HomeMenu from 'modules/HomeMenu';
import Voting from 'controls/Voting';
import VotingMenu from 'controls/VotingMenu';
import Add from 'controls/Add';
import Users from 'controls/Users';
import Title from 'controls/Title';
import Timer from 'controls/Timer';
import Time from 'controls/Time';
import IceBreaker from 'controls/IceBreaker';
import Settings from 'controls/Settings';
import StagePanel from 'controls/StagePanel';
import Summary from 'controls/Summary';
import Highlight from 'controls/Highlight';
import Sorting from 'controls/Sorting';
import MaskComments from 'controls/MaskComments';
import Stats from 'controls/Stats';

import Page from 'modules/Page';
import OnVoting from 'modules/OnVoting';
import Loader from 'modules/Loader';
import ProgressLoader from 'modules/ProgressLoader';
import RetroSummary from 'modules/RetroSummary';

import BoardDisconnected from 'modules/BoardDisconnected';
import { getImageUrl } from 'images/background';

import { isTouchScreen, moveCursor } from 'utils/misc';
import logger from 'utils/logger';

import Board from './Board';
import Media from './Media';
import ColumnHeading from './ColumnHeading';

import './DashBoard.css';
import CheckIn from 'controls/CheckIn';
import RetroMood from 'modules/RetroMood';
import Feedback from 'controls/Feedback';
import { getBoardRemainingDays } from 'utils/misc';
import ArchivedMsg from 'modules/ArchivedMsg';
import BoardSurvey from 'pulse/BoardSurvey';
import Survey from 'controls/Survey';
import PulseTitle from 'controls/PulseTitle';

let reconnectTimes = 0;
let disconnectReason = '';

const DEFAULT_VOTES = 3;

const DashboardContent = ({ id, socket, data, isPulseSurvey }) => {
  const [cookies] = useCookies(['uid']);
  const { isAuthenticated, profile, userMeta } = useContext(AppContext);
  const { compact, teamId, createdBy, orgId, membersCount, planId, isPrivate, timestamp, startMoodPoll } = data;

  const [name, setName] = useState(data.name);
  const [columns, setColumns] = useState(data.columns || []);
  const [isAnonymous, setIsAnonymous] = useState(data.isAnonymous);
  const [theme, setTheme] = useState(data.theme);
  const [backgroundUrl, setBackgroundUrl] = useState(data.backgroundUrl);
  const [background, setBackground] = useState();
  const [users, setUsers] = useState({});
  const [currentBoardId, setCurrentBoardId] = useState(compact ? 'compact' : 0);
  const [user, setUser] = useState();
  const [allNotes, setAllNotes] = useState({});
  const [allVotes, setAllVotes] = useState({});
  const [allStatus, setAllStatus] = useState([]);
  const [progress, setProgress] = useState();
  const [remainingVotes, setRemainingVotes] = useState(DEFAULT_VOTES);
  const [votingStates, setVotingStates] = useState({
    status: '',
    maxVotes: DEFAULT_VOTES,
    contentId: 0,
    existingVotes: {}
  });
  const [timerStates, setTimerStates] = useState({});
  const [mediaStates, setMediaStates] = useState({});
  const [retroStates, setRetroStates] = useState();
  const [stageStates, setStageStates] = useState({});

  const [notesStates, setNotesStates] = useState({});
  const [isOnTimer, setIsOnTimer] = useState(false);
  const [titleEditor, setTitleEditor] = useState(false);
  const [columnEditor, setColumnEditor] = useState(false);

  const [sortBy, setSortBy] = useState();

  const [socketDisconnected, setSocketDisconnected] = useState(false);

  const { role } = getRole({ orgId, teamId, userMeta });

  const isFacilitator = teamId ? checkPermission(role, 'board:edit') : Boolean(createdBy === user?.id);

  const socketSend = useCallback(
    (name, config) => {
      if (socket.disconnected) {
        // try {
        //   Sentry.captureMessage(
        //     `Disconnect - ${name} - ${
        //       socket.io.engine.transport.polling === true ? 'polling' : 'websocket'
        //     } - ${reconnectTimes} - ${disconnectReason}`
        //   );
        // } catch (e) {
        //   Sentry.captureException(e);
        // }

        setSocketDisconnected(true);
      } else {
        socket.emit(name, config);
      }
    },
    [socket]
  );

  const onVote = ({ allowedVotes, resetVotes }) => {
    const newStatus = votingStates.status === 'vote' ? 'endVote' : 'vote';
    const newStates = {
      contentId: Boolean(newStatus === 'vote') ? currentBoardId : votingStates.contentId,
      status: newStatus,
      maxVotes: parseInt(allowedVotes, 10),
      resetVotes
    };

    setVotingStates(newStates);

    socketSend('update', {
      room: id,
      votingStates: newStates
    });

    if (allStatus && allStatus.length > 0) {
      onSave({
        boardId: currentBoardId,
        status: []
      });

      //Sentry.captureMessage(`Reset status in room - ${id}`);
    }
  };

  const onTimer = ({ timerConfig }) => {
    socketSend('update', {
      room: id,
      timerStates: timerConfig
    });
  };

  const onBroadcast = ({ mediaConfig }) => {
    socketSend('update', {
      room: id,
      mediaStates: mediaConfig
    });
  };

  const onMaskComments = ({ maskComments }) => {
    socketSend('update', {
      room: id,
      retroStates: {
        ...retroStates,
        showSummary: false,
        maskComments
      }
    });
  };

  const onRetroSummary = ({ showSummary }) => {
    socketSend('update', {
      room: id,
      retroStates: {
        ...retroStates,
        moodPoll: retroStates.moodPoll ? false : retroStates.moodPoll,
        showMood: false,
        showSummary
      }
    });
  };

  const onMoodPoll = ({ moodPoll, showMood }) => {
    socketSend('update', {
      room: id,
      retroStates: {
        ...retroStates,
        showSummary: false,
        moodPoll,
        showMood
      }
    });
  };

  const onSurvey = ({ pulse }) => {
    socketSend('update', {
      room: id,
      retroStates: {
        ...retroStates,
        pulse
      }
    });
  };

  const onHighlight = ({ showHighlight }) => {
    socketSend('update', {
      room: id,
      retroStates: {
        ...retroStates,
        showSummary: false,
        showHighlight
      }
    });
  };

  const onGifsUpdate = ({ disableGifs }) => {
    socketSend('update', {
      room: id,
      retroStates: {
        ...retroStates,
        showSummary: false,
        disableGifs
      }
    });
  };

  const onStage = ({ gifs }) => {
    socketSend('update', {
      room: id,
      stageStates: {
        gifs
      }
    });
  };

  const onSettingsUpdate = ({ name, columns, isAnonymous, theme, backgroundUrl }) => {
    socketSend('settings-update', {
      room: id,
      name,
      columns,
      isAnonymous,
      theme,
      backgroundUrl
    });
  };

  const onSave = useCallback(
    ({ boardId, notes, type, votes, highlights, status, newAction }) => {
      if (newAction && !newAction.addedIn) {
        newAction.addedIn = id;
      }

      socketSend('message', {
        room: id,
        contentId: boardId,
        notes,
        type,
        votes,
        highlights,
        status,
        newAction
      });
    },
    [id, socketSend]
  );

  const onChange = (value) => {
    setCurrentBoardId(value);
  };

  const getBoard = (i) => {
    return (
      <Board
        boardId={i}
        user={user}
        remoteNotes={allNotes[i] || []}
        remoteType={allNotes[`${i}-type`]}
        remoteStatus={{
          editingStatus: allStatus || [],
          maskComments: retroStates ? retroStates.maskComments : null,
          sortingStatus: retroStates ? retroStates.sortBy : null
        }}
        votes={allVotes[i] || {}}
        onSave={onSave}
        updateRemainingVotes={setRemainingVotes}
        boardSettings={{ compact, columns, isAnonymous, sortBy }}
        votingStates={votingStates}
        setSortBy={setSortBy}
      />
    );
  };

  const renderContent = () => {
    const layout = columns;

    return layout.map((item, i) => ({
      tabButton: <ColumnHeading text={item} />,
      tabContent: <div className="grid">{getBoard(i)}</div>
    }));
  };

  const renderCompactContent = () => {
    const boardColumns = columns.map((item, i) => (
      <div key={`board-column-${i}`} className="board-column">
        <h3>
          <ColumnHeading text={item} />
        </h3>
      </div>
    ));

    return (
      <>
        <div className="board-columns-wrapper">
          {isFacilitator ? (
            <div className="board-columns" onClick={() => setColumnEditor(true)} style={{ cursor: 'pointer' }}>
              {boardColumns}
            </div>
          ) : (
            <div className="board-columns">{boardColumns}</div>
          )}
        </div>

        <div className="grid compact-grid">{getBoard('compact')}</div>
      </>
    );
  };

  useEffect(() => {
    const loadThemeImage = () => {
      if (!theme) return;

      const src = getImageUrl(theme, 'l');
      const imageLoader = new Image();
      imageLoader.src = src;

      imageLoader.onload = () => {
        setBackground(src);
      };
    };

    if (backgroundUrl) {
      const imageLoader = new Image();
      imageLoader.src = backgroundUrl;

      imageLoader.onload = () => {
        setBackground(backgroundUrl);
      };

      imageLoader.onerror = () => {
        loadThemeImage();
      };
    } else {
      loadThemeImage();
    }
  }, [theme, backgroundUrl]);

  useEffect(() => {
    socket.open();

    socket.on('connect', () => {
      const { sub, name, nickname, picture, given_name, family_name } = profile || {};
      const user = {
        id: sub || cookies.uid,
        anonymous: !profile,
        name,
        nickname,
        picture,
        given_name,
        family_name
      };

      if (user.id) {
        setUser(user);
        socketSend('room', { room: id, cols: columns.length, user, compact });
      } else {
        axios
          .get(`/uid`)
          .then(({ data }) => {
            user.id = data;
            setUser(user);
            socketSend('room', { room: id, cols: columns.length, user, compact });
          })
          .catch((e) => {
            Sentry.captureException(e);
          });
      }

      try {
        reconnectTimes += 1;
        if (reconnectTimes === 150 || reconnectTimes === 300) {
          // Sentry.captureMessage(
          //   `connect over ${reconnectTimes} - ${
          //     socket.io.engine.transport.polling === true ? 'polling' : 'websocket'
          //   } - ${disconnectReason}`
          // );

          if (reconnectTimes === 300) {
            socket.close();
          }

          if (reconnectTimes === 150 && socket.io.engine.transport.polling) {
            socket.close();
          }
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    });

    socket.on('disconnect', (reason) => {
      logger('disconnect', reason);

      disconnectReason = reason;

      // Switch/close tab: io client disconnect|io server disconnect
      //if (reason !== 'io client disconnect') {
      //Sentry.captureMessage(`Disconnect reason: ${reason}`);
      //}
    });

    socket.on('join', (users) => {
      setUsers(users);
    });

    socket.on('settings-update', ({ name, columns, isAnonymous, theme, backgroundUrl }) => {
      if (name !== undefined) {
        setName(name);
      }
      if (columns !== undefined) {
        setColumns(columns);
      }
      if (isAnonymous !== undefined) {
        setIsAnonymous(isAnonymous);
      }
      if (theme !== undefined) {
        setTheme(theme);
      }

      if (backgroundUrl || (theme && backgroundUrl === undefined))
        //Important: backgrounUrl could be undefined
        setBackgroundUrl(backgroundUrl);
    });

    socket.on('update', ({ votingStates, timerStates, mediaStates, retroStates, stageStates }) => {
      if (votingStates && votingStates.status) {
        setVotingStates(votingStates);

        if (votingStates.status === 'endVote') {
          setProgress();
        }
      }

      if (timerStates) {
        setTimerStates(timerStates);
      }

      if (mediaStates) {
        setMediaStates(mediaStates);
      }

      if (retroStates) {
        setRetroStates(retroStates);
      }

      if (stageStates) {
        setStageStates(stageStates);
      }
    });

    socket.on('message', ({ contentId, notes, type, votes, votesCount, highlights, status }) => {
      if (notes) {
        setAllNotes((currentNotes) => ({
          ...currentNotes,
          [contentId]: notes,
          [`${contentId}-type`]: type
        }));
      }

      if (votes) {
        setAllVotes((currentVotes) => ({
          ...currentVotes,
          [contentId]: votes
        }));
      }

      if (votesCount !== undefined) {
        setProgress(votesCount);
      }

      if (highlights !== undefined) {
        setNotesStates((currentNotesStates) => ({
          ...currentNotesStates,
          contentId,
          highlights
        }));
      }

      if (Array.isArray(status)) {
        setAllStatus(status);
      }
    });

    // socket.on('error', (e) => {
    //   Sentry.captureMessage(`Socket client error - ${e.toString()}`);
    // });

    socket.io.on('reconnect', (attempt) => {
      if (attempt > 49) {
        Sentry.captureMessage(`Reconnect - ${id}: ${attempt}`);
      }
    });

    return () => {
      socket.close();
    };
    // column change causing too much re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, socket, cookies.uid, profile, socketSend]); //columns.length, compact

  useEffect(() => {
    if (!socket.disconnected) {
      setSocketDisconnected(false);
      if (reconnectTimes === 50) {
        Sentry.captureMessage(`Set socketDisconnected to false - ${reconnectTimes} - ${disconnectReason}`);
      }
    }
  }, [socket.disconnected]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // To do: not cross browsers!!!
      if (document.hidden) {
        socket.close();
      } else {
        socket.open();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [socket]);

  useEffect(() => {
    const container = document.querySelector(`.react-stickies-wrapper-${currentBoardId}`);
    const cursor = container?.querySelector('.magic-cursor');

    if (!retroStates?.showHighlight || !isFacilitator || isTouchScreen()) {
      cursor && cursor.classList.remove('magic-cursor-visible');
      return;
    }

    if (retroStates?.showHighlight !== user?.id) {
      // Not started by this user
      return;
    }

    let isInElement = false;
    const mainBoard = document.querySelector('#boardMainContent');

    if (!mainBoard) return;

    cursor && cursor.classList.add('magic-cursor-visible');

    const handleMouseMove = (event) => {
      var diff = 10;
      const { clientX, clientY } = event;

      // Get the bounding rectangle of target
      const gridLayout = container?.querySelector('.react-grid-layout');
      const rect = gridLayout?.getBoundingClientRect();

      if (!rect) return;

      // Mouse position
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      if (!isInElement) {
        cursor.style.left = x - diff + 'px';
        cursor.style.top = y - diff + 'px';
        cursor.style.width = '20px';
        cursor.style.height = '20px';
        cursor.style.borderRadius = '50%';
      }
    };

    const enterCursor = (event) => {
      const elem = event.target.closest('.noteContainer');

      if (elem) {
        isInElement = true;
        const highlightedId = elem.dataset.id;

        //relay on retroStates update seems fast enough
        //moveCursor({ targetElem: elem, cursor });

        onSave({ boardId: currentBoardId, highlights: highlightedId });
      } else {
        isInElement = false;
        onSave({ boardId: currentBoardId, highlights: null });
      }

      //This affect emoji hover
      //event.stopPropagation();
    };

    mainBoard.addEventListener('mousemove', handleMouseMove);
    mainBoard.addEventListener('mouseover', enterCursor);

    return () => {
      mainBoard.removeEventListener('mousemove', handleMouseMove);
      mainBoard.removeEventListener('mouseover', enterCursor);
    };
  }, [currentBoardId, retroStates, isFacilitator, onSave, user]);

  useEffect(() => {
    const contentId = notesStates.contentId;
    const noteId = notesStates.highlights;

    if (
      contentId === null ||
      contentId === undefined ||
      !noteId ||
      contentId !== currentBoardId ||
      !allNotes[currentBoardId]
    )
      return;

    const container = document.querySelector(`.react-stickies-wrapper-${contentId}`);
    const cursor = container.querySelector('.magic-cursor');
    const elem = document.querySelector(`[data-id='${noteId}']`);

    moveCursor({ targetElem: elem, cursor });

    return () => {
      cursor.style.opacity = '0';
    };
  }, [notesStates.contentId, notesStates.highlights, currentBoardId, allNotes]);

  if (!planId) {
    const remainingDays = getBoardRemainingDays(timestamp);

    if (remainingDays < 1) {
      return (
        <div
          className={`boardMain boardMain-authenticated-${isAuthenticated}`}
          style={{
            backgroundImage: background ? `url("${background}")` : 'none',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ArchivedMsg name={name} teamId={teamId} isAuthenticated={isAuthenticated} />
        </div>
      );
    }
  }

  if (isPulseSurvey) {
    return (
      <div className={`boardMain boardMain-authenticated-${isAuthenticated} boardMain-pulse`}>
        <div className="boardMenu">
          <HomeMenu teamId={role ? teamId : null} boardId={id} />
          <PulseTitle
            name={name}
            setTitleEditor={setTitleEditor}
            isFacilitator={isFacilitator}
            isPrivate={isPrivate}
            teamId={teamId}
          />

          <div className="boardMenu-controls">
            <Feedback id={id} uid={user?.id} />
            <Users users={users} />
            <Add
              actionNotes={allNotes['action'] || []}
              onSave={onSave}
              user={user}
              boardId={id}
              teamId={teamId}
              isFacilitator={isFacilitator}
            />
            <Time timerStates={timerStates} isOnTimer={isOnTimer} setIsOnTimer={setIsOnTimer} />
          </div>
        </div>

        {isFacilitator && (
          <div className="boardMenu boardMenu-admin">
            <Settings
              boardData={data}
              name={name}
              titleEditor={titleEditor}
              setTitleEditor={setTitleEditor}
              onSettingsUpdate={onSettingsUpdate}
            />
            <Survey id={id} onSurvey={onSurvey} retroStates={retroStates} surveyData={data} />
          </div>
        )}

        <div id="boardMainContent" className={`main ${isFacilitator ? 'main-admin' : ''}`}>
          <div className="panel">
            <BoardSurvey surveyData={data} retroStates={retroStates} actions={allNotes['action'] || []} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`boardMain boardMain-authenticated-${isAuthenticated}`}
        style={{ backgroundImage: background ? `url("${background}")` : 'none' }}
      >
        {!background && (
          <Skeleton variant="rect" animation="wave" width="100%" height={'100vh'} className="board-loading-image" />
        )}

        {socket.disconnected || Object.keys(allNotes).length === 0 ? <ProgressLoader /> : null}

        <div className="boardMenu">
          <HomeMenu teamId={role ? teamId : null} boardId={id} />
          <Title
            name={name}
            setTitleEditor={setTitleEditor}
            isFacilitator={isFacilitator}
            planId={planId}
            isPrivate={isPrivate}
            teamId={teamId}
          />

          {<VotingMenu votingStates={votingStates} progress={progress} remainingVotes={remainingVotes} />}

          <div className="boardMenu-controls">
            <Feedback id={id} uid={user?.id} />

            <Stats id={id} teamId={teamId} retroStates={retroStates} uid={profile?.sub || cookies.uid} />
            <Users users={users} />
            <Add
              actionNotes={allNotes['action'] || []}
              onSave={onSave}
              user={user}
              boardId={id}
              teamId={teamId}
              isFacilitator={isFacilitator}
            />
            <Time timerStates={timerStates} isOnTimer={isOnTimer} setIsOnTimer={setIsOnTimer} />
          </div>
        </div>
        {isFacilitator && (
          <div className="boardMenu boardMenu-admin">
            <Settings
              boardData={data}
              name={name}
              columns={columns}
              isAnonymous={isAnonymous}
              theme={theme}
              backgroundUrl={backgroundUrl}
              onSettingsUpdate={onSettingsUpdate}
              titleEditor={titleEditor}
              setTitleEditor={setTitleEditor}
              columnEditor={columnEditor}
              setColumnEditor={setColumnEditor}
              disableGifs={retroStates?.disableGifs}
              onGifsUpdate={onGifsUpdate}
              setBackground={setTheme}
              setBackgroundUrl={setBackgroundUrl}
            />

            <CheckIn
              id={id}
              onMoodPoll={onMoodPoll}
              retroStates={retroStates}
              teamId={teamId}
              startMoodPoll={startMoodPoll}
            />

            <IceBreaker onBroadcast={onBroadcast} mediaStates={mediaStates} teamId={teamId} />
            <MaskComments id={id} onMaskComments={onMaskComments} maskComments={retroStates?.maskComments} />
            <Timer onTimer={onTimer} timerStates={timerStates} isOnTimer={isOnTimer} setIsOnTimer={setIsOnTimer} />
            <Voting onVote={onVote} votingStates={votingStates} currentVotes={allVotes[currentBoardId] || {}} />
            <Sorting setSortBy={setSortBy} votingStates={votingStates} />
            {!isTouchScreen() && (
              <Highlight user={user} onHighlight={onHighlight} showHighlight={retroStates?.showHighlight} />
            )}
            <Summary
              id={id}
              onRetroSummary={onRetroSummary}
              teamId={teamId}
              membersCount={membersCount}
              planId={planId}
            />
          </div>
        )}

        {/*#boardMainContent is used for inject timer notification */}
        {columns && (
          <div id="boardMainContent" className={`main ${isFacilitator ? 'main-admin' : ''}`}>
            <div className={`panel ${votingStates.status}`}>
              {compact ? (
                renderCompactContent()
              ) : (
                <NavPills tabs={renderContent()} onChange={onChange} active={currentBoardId} />
              )}
            </div>
          </div>
        )}

        {mediaStates.source && <Media mediaStates={mediaStates} />}
      </div>
      <StagePanel
        disableGifs={retroStates ? retroStates.disableGifs : true}
        stageStates={stageStates}
        onStage={onStage}
      />
      <RetroMood retroStates={retroStates} id={id} teamId={teamId} />
      <OnVoting isFacilitator={isFacilitator} votingStates={votingStates} columns={columns} compact={compact} />
      <RetroSummary
        retroStates={retroStates}
        id={id}
        teamId={teamId}
        actions={allNotes['action'] || []}
        isFacilitator={isFacilitator}
        membersCount={membersCount}
        planId={planId}
      />
      {socketDisconnected && <BoardDisconnected />}
    </>
  );
};

const Dashboard = ({ isPulseSurvey }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [socket, setSocket] = useState();
  const [error, setError] = useState();
  const { name } = data;
  const { loginUrl } = useSsoConnection({ boardId: id, isDeepLink: true });

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(`/api/${isPulseSurvey ? 'pulse-survey' : 'board'}/${id}`)
      .then(({ data }) => {
        setData(data);

        const socket = io(`${APP_ENDPOINT}`, {
          path: '/board/socket.io',
          secure: true,
          autoConnect: false,
          timeout: 5000,
          reconnectionAttempts: 50,
          transports: ['websocket'],
          transportOptions: {
            polling: {
              requestTimeout: 25000 // This should be at least pingInterval + pingTimeout on the server side
            }
          }
        });

        // on reconnection, reset the transports option, as the Websocket
        // connection may have failed (caused by proxy, firewall, browser, ...)
        socket.on('reconnect_attempt', () => {
          socket.io.opts.transports = ['polling', 'websocket'];
          //Sentry.captureMessage(`Try polling on board - ${id}`);
        });

        setSocket(socket);
      })
      .catch((e) => {
        setError(e);
      });

    return () => {
      source.cancel();
    };
  }, [id, isPulseSurvey]);

  useEffect(() => {
    const boardId = id?.split(' ')[0];

    if (boardId !== id) {
      // If the ID contains spaces, redirect to the clean version
      Sentry.captureMessage(`Reload - ${id}`);
      window.location.replace(`/${isPulseSurvey ? 'pulse-survey' : 'board'}/${boardId}`);
    }
  }, [id, isPulseSurvey]);

  if (!socket && !error) {
    return <Loader />;
  }

  return (
    <>
      {name && (
        <Helmet>
          <title>{`TeleRetro - ${name}`} </title>
        </Helmet>
      )}
      {socket && <DashboardContent id={id} socket={socket} data={data} isPulseSurvey={isPulseSurvey} />}
      {error && (
        <div className="staticPage invitePage">
          <Page boardId={id}>
            <div className="staticPage-container">
              <div className="staticPage-content">
                <div className="welcomeModal">
                  {error.response?.status === 404 && (
                    <h3>
                      Sorry we can’t find this page{' '}
                      <span role="img" aria-label="">
                        🤔
                      </span>
                    </h3>
                  )}
                  {error.response?.status === 401 && (
                    <>
                      <h3>
                        Sign in required{' '}
                        <span role="img" aria-label="">
                          🔐
                        </span>
                      </h3>
                      <p>You need to be signed in to access this page</p>
                      <Button href={loginUrl}>Sign in</Button>
                    </>
                  )}
                  {error.response?.status === 403 && (
                    <>
                      <h3>
                        Invitation required{' '}
                        <span role="img" aria-label="">
                          🔐{' '}
                        </span>
                      </h3>
                      <p>You need to be a member of this team to view this page</p>
                      <p>Please ask your admin to send you an invite link</p>
                    </>
                  )}
                  {error.response?.status !== 404 && error.response?.status !== 401 && error.response?.status !== 403 && (
                    <h3>
                      Oops, something went wrong{' '}
                      <span role="img" aria-label="">
                        😳
                      </span>
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </Page>
        </div>
      )}
    </>
  );
};

export default Dashboard;
