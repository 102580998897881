import React, { useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import NotificationBar from 'modules/NotificationBar';

import apiService from 'utils/apiService';
import PulsePanel from './PulsePanel';
import PulsePromo from './PulsePromo';

const TeamPulse = ({ teamId, canCreateBoard, isArchived }) => {
  const [pulseModels, setPulseModels] = useState();
  const [error, setError] = useState();

  const renderData = (data) => {
    const { title } = data;
    return (
      <Paper key={`${data.id}-team-pulse`} className="pulseDashboard">
        <div className="teamPulseContainer" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="pulseSection">
            <h2>{title}</h2>
          </div>

          <PulsePanel teamId={teamId} modelData={data} canCreateBoard={canCreateBoard} isArchived={isArchived} />
        </div>
      </Paper>
    );
  };

  useEffect(() => {
    if (teamId) {
      apiService
        .get(`/api/team/${teamId}/pulse-models`)
        .then(({ data }) => {
          setPulseModels(data);
        })
        .catch((e) => {
          setPulseModels([]);
          setError(e);
        });
    }
  }, [teamId]);

  return (
    <>
      {Array.isArray(pulseModels) && pulseModels.length > 0 ? (
        <>{pulseModels.map(renderData)}</>
      ) : (
        <>{Array.isArray(pulseModels) && <PulsePromo isTeam isFullWidth />}</>
      )}
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </>
  );
};

export default TeamPulse;
